@mixin label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8rem;
}

.patients-number {
  @include label;
}

.constructor-packages {
  margin-top: 20px;
  .label {
    @include label;
  }
  button {
    text-transform: capitalize;
  }
}

.package {
  display: flex;
  align-items: center;
  div {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.package-number {
  margin-right: 5px;
}

.delete-package {
  width: 70px;
}
