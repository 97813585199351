.grid-item-view {
  font-family: 'Inter', sans-serif;

  .MuiGrid-root.grid-column {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 15px;
    letter-spacing: -0.023em;
    line-height: 150%;
    font-weight: 400;

    .MuiSvgIcon-root.icon {
      margin-right: 11px;
      position: relative;
      font-size: 16px;
      top: 2px;
    }
  }
}
