@mixin button {
  text-transform: none;
}

.asset-content {
  .asset-wrapper {
    min-height: 280px;
    min-width: 600px;

    audio {
      height: 40px;
    }

    .asset-preview {
      .MuiCircularProgress-root {
        color: #1976d2;
      }
    }
  }

  button {
    @include button;
  }

  span {
    @include button;
  }

  .asset-description {
    margin-bottom: 20px;
    .MuiDataGrid-columnHeaders {
      display: none;
    }
  }
}
