.ui-text-field {
  min-width: 70px;

  .MuiInput-root {
    font-size: 0.89rem;

    &:before {
      border-bottom: none;
    }
    &:after {
      border-bottom: none;
    }

    .ui-select-placeholder {
      color: grey;
    }

    .MuiInput-input {
      &.Mui-disabled {
        -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
        color: rgba(0, 0, 0, 0.87);
      }
    }

    &.Mui-error {
      .MuiInput-input {
        color: #d32f2f;

        &::placeholder {
          opacity: 1;
        }
      }
    }

    &.MuiInput-underline {
      &:before {
        border-bottom: none !important;
      }
    }
  }
}
