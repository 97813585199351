.asset-audio-wrapper {
  width: 100%;

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .description-wrapper {
    position: absolute;
    bottom: 0;
  }
}
