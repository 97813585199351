.activity-item {
  display: flex;
  align-items: center;
  div {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &-transparent {
    @extend .activity-item;
    opacity: 0.5;
  }
}

.drag-indicator {
  cursor: grab;
}