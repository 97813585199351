$progress-color: #fffaf0;

.asset-preview {
  position: relative;
  display: flex;
  align-items: center;

  .viewer-wrapper {
    background: #dcdcdc;
    text-align: center;
  }

  img,
  audio,
  video {
    &.loaded {
      visibility: visible;
    }
    &.loading {
      visibility: hidden;
    }
  }

  audio {
    height: 29px;
  }

  .MuiCircularProgress-root {
    color: $progress-color;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
