.asset-dialog {
  z-index: 2;

  .MuiDialog-container {
    .MuiPaper-root {
      min-width: 70%;
    }
  }

  .asset-picker-actions {
    button {
      text-transform: none;
    }

    .MuiTableCell-root {
      border-bottom: none;
    }
  }
}
