.confirm-modal {
  position: relative;

  .MuiDialogTitle-root {
    font-size: 1.15rem;
  }

  .close-icon {
    position: absolute;
    right: 1px;
    top: 1px;

    svg {
      font-size: 1.05rem;
    }
  }

  .confirm-modal-content {
    .MuiDialogContentText-root {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .MuiDialogActions-root {
    .MuiButton-root {
      margin-left: 0;
    }
  }
}
