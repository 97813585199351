.button-generator_wrapper {
  button {
    margin: 0 5px;

    .button-content {
      display: inline-flex;
      align-items: center;
      visibility: visible;
    }

    &.loading {
      .button-content {
        visibility: hidden;
      }
    }

    &.MuiButton-root {
      &.Mui-disabled {
        color: gray;
      }
    }

    .progress-loader {
      position: absolute;
      color: #fff;
    }
  }
}

.menu-item-generator {
  .MuiButton-root {
    min-width: 55px;
  }
}
