body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin emptyTable {
  display: flex;
  justify-content: center;
  height: inherit;
  min-height: 75px;
  align-items: center;
}

.app {
  min-height: 100vh;

  .--label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.8rem;
    line-height: 1.5rem;
  }

  .--readonly-value {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.68);
  }

  .entity-table_no-results {
    @include emptyTable;
  }

  .entity-table_not-found {
    @include emptyTable;
  }

  .css-1xy1myn-MuiDataGrid-root {
    border: none;
  }
}

.Mui-disabled {
  opacity: 0.5;
}

a {
  color: blue;
}
