.ui-select {
  min-width: 70px;

  &.MuiInput-root {
    font-size: 0.89rem;

    &:before {
      border-bottom: none;
    }
    &:after {
      border-bottom: none;
    }

    .ui-select-placeholder {
      color: grey;
    }

    &.Mui-error {
      .ui-select-placeholder {
        color: #d32f2f;
      }
    }

    &.MuiInput-underline {
      &:before {
        border-bottom: none !important;
      }
    }
  }
}

.ui-select-option {
  font-size: 0.89rem !important;
}
