$assetHeight: 300px;
$assetMinWidth: 450px;

.react-draft-container {
  margin: 13px 0;
  &.disabled {
    .react-draft-toolbar {
      display: none;
      pointer-events: none;
    }
  }
  figure {
    text-align: center;
    width: auto;
    min-height: $assetHeight;

    img {
      min-width: $assetMinWidth;
      height: $assetHeight;
      width: auto;
      object-fit: contain;
    }

    iframe {
      min-width: $assetMinWidth;
      height: $assetHeight;
    }

    .rdw-image-alignment-options-popup {
      display: none;
    }
  }
  .MuiSvgIcon-root {
    padding: 4px;

    &:hover {
      box-shadow: 1px 1px 0px #bfbdbd;
    }
  }
}

.react-draft-wrapper {
  border: 1px solid #a1a1a1;
}

.react-draft-header {
  margin: 15px 0 0 0;
  font-size: 0.8rem;
  display: inline-block;
  background: gray;
  color: white;
  padding: 3px 8px;
}

.react-draft-editor {
  min-height: 200px;
  padding: 3px;
}

.react-draft-toolbar {
  border-bottom: 1px solid #808080;
}
