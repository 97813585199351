@mixin empty {
  display: flex;
  justify-content: center;
  height: inherit;
  min-height: 75px;
  align-items: center;
}

@mixin label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8rem;
}

.tags-autocomplete {
  display: inline-block;
  min-width: 160px;

  .MuiAutocomplete-endAdornment {
    display: none;
  }

  .MuiInput-root {
    &:before {
      border-bottom: none;
    }
    &:after {
      border-bottom: none;
    }
  }

  .MuiInput-underline {
    &:before {
      border-bottom: none !important;
    }
  }

  .MuiChip-label {
    font-size: 0.86rem;
  }

  input {
    cursor: pointer;
    min-width: 10px !important;
  }
}

.tags-autocomplete-popper {
  .MuiAutocomplete-groupLabel {
    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 0 15px 15px 15px;
}

.entity-table_no-results {
  @include empty;
}

.entity-table_not-found {
  @include empty;
}

.linked-table_wrapper {
  padding-bottom: 13px;

  .label {
    line-height: 3rem;
    @include label;
  }
}

.MuiFormControlLabel-label {
  &.Mui-disabled {
    -webkit-text-fill-color: black;
  }
}

.post-image-handler {
  span {
    text-transform: none;
  }
}
