@mixin label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8rem;
}

.constructor_questions {
  margin: 17px 0;
  .label {
    @include label;
  }
  button {
    text-transform: capitalize;
  }
}

.questions-autocomplete {
  display: flex;
  div {
    margin-top: 2px;
    margin-bottom: 2px;
  }
}

.delete-question {
  width: 70px;
}

.add-question {
  text-transform: capitalize;
  margin-top: 50px;
}

.item-columns {
  display: flex;
}

.position-column {
  display: flex;
  flex-direction: column;
  padding: 19px 0;
  justify-content: space-between;
}

.content-column {
  flex-grow: 1;
}

.position-column-item {
  margin-right: 20px;
}
