.breadcrumb-label {
  color: #000000;
  &:hover {
    text-decoration: underline;
  }
}

.breadcrumb-link {
  color: #888888;
  text-decoration: none;
}
