.sign-in-box {
  margin: 80px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .MuiAvatar-root {
    background: #9932cc;
  }

  .action-box {
    min-width: 75%;

    .sign-in-button {
      margin: 30px 0;
      text-transform: none;
    }
  }
}
