$data-grid-font-size: 0.75rem;

@mixin label-top {
  transform: translate(0, -1.5px) scale(0.75);
  -webkit-transform: translate(0, -1.5px) scale(0.75);
}

@mixin label-bottom {
  transform: translate(0px, 16px) scale(1);
  -webkit-transform: translate(0px, 16px) scale(1);
}

@mixin disabledTextColor {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.68);
}

.error {
  label {
    color: #d32f2f;
  }
}

.mui-custom {
  label {
    &.MuiInputLabel-formControl {
      &.Mui-focused {
        @include label-top;
      }
      &.MuiFormLabel-filled {
        @include label-top;
      }
    }
  }

  .MuiInput-root {
    .Mui-disabled {
      @include disabledTextColor;
    }
    &.MuiInput-underline {
      &.Mui-disabled {
        &:before {
          border-bottom: none;
        }
      }
    }
  }

  .clear-button {
    display: none;
    padding: 0;
  }

  &.filled {
    .MuiSelect-iconStandard {
      display: none;
    }
    .clear-button {
      display: inherit;
    }
  }

  .Mui-disabled {
    svg {
      display: none;
    }
    @include disabledTextColor;
  }

  .MuiFormHelperText-root {
    margin-left: 0;
  }

  .MuiButton-root {
    position: relative;

    .btn-text {
      visibility: visible;
    }

    .loading {
      .progress-loader {
        position: absolute;
      }

      .btn-text {
        visibility: hidden;
      }
    }
  }
}

.custom-data-grid {
  .MuiDataGrid-columnHeader {
    .MuiDataGrid-columnSeparator {
      display: none;
    }
    .MuiDataGrid-columnHeaderDraggableContainer {
      .MuiDataGrid-columnHeaderTitleContainer {
        .MuiDataGrid-iconButtonContainer {
          .MuiIconButton-root {
            visibility: visible;

            .MuiSvgIcon-root {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .MuiDataGrid-footerContainer {
    .MuiTablePagination-root {
      .MuiToolbar-gutters {
        .MuiTablePagination-selectLabel {
          font-size: $data-grid-font-size;
        }
        .MuiInputBase-root {
          font-size: $data-grid-font-size;
        }
        .MuiTablePagination-displayedRows {
          font-size: $data-grid-font-size;
        }
      }
    }
  }
}
