$assetOpacity: 0.55;

@mixin darkThemeItemBackground {
  background: #536dfecb;
}

@mixin whiteThemeItemBackground {
  background: rgb(245, 245, 245);
}

.asset-grid-container {
  height: 70vh;
  overflow: scroll;
  margin-bottom: 2.5rem;
  align-items: flex-start;

  &.MuiGrid-container {
    .grid-item {
      .asset-wrapper {
        position: relative;
        margin: 4px;
        outline: none;
        .description {
          margin: 4px;
          font-size: 0.6rem;
          line-height: 1.25;
        }
      }
    }
  }

  &.dark {
    .MuiGrid-item {
      .asset-wrapper {
        &:focus {
          @include darkThemeItemBackground;
          img {
            opacity: $assetOpacity;
          }
          video {
            opacity: $assetOpacity;
          }
        }
      }
      .asset-wrapper {
        background: rgba(0, 0, 0, 0.845);
      }
    }
  }

  .MuiGrid-item {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .asset-wrapper {
      overflow-wrap: break-word;

      .description {
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        display: -webkit-box;
        overflow: hidden;
        color: white;
      }

      &:focus {
        @include whiteThemeItemBackground;
      }
    }
  }

  .progress-wrapper {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .MuiLinearProgress-root {
    background: inherit;

    span {
      background-color: dimgray;
    }
  }
}
