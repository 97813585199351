.MuiList-root {
  &.app-navigation {
    padding-top: 0;

    div:first-child a {
      padding: 16px 16px;
    }

    div a {
      padding: 8px 16px;
    }

    .MuiListItem-root {
      &.active {
        background: rgba(0, 0, 0, 0.04);
        span {
          color: #1976d2;
        }
      }

      svg {
        color: gray;
        margin: 0px 33px 0 0;
      }
    }
  }
}
