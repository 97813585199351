.asset-upload-dialog {
  .dialog-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-actions {
    padding: 22px;
  }
}
