.collapsible-list {
  .action-wrapper {
    cursor: pointer;

    .icon.MuiSvgIcon-root {
      margin-right: 4px;
      position: relative;
      font-size: 16px;
      top: 3px;
      color: rgb(130, 130, 130);
    }

    .label {
      font-size: 0.8rem;
      color: rgb(128, 128, 128);
    }
  }

  .opacity-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: white;
    opacity: 0;
    transition: opacity ease-out 0.25s;
  }

  .list-wrapper {
    position: relative;
    overflow: hidden;
    transition: all ease-in 0.25s;

    .list-item {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
