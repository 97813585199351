.image-viewer {
  position: relative;
  display: inline-block;
  margin: 13px 0;

  .viewer-wrapper {
    background: #dcdcdc;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .label {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.6);
  }

  .photo-icon {
    font-size: 3.5rem;
  }
}

.modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2px;
  height: 80vh;
}
