.entity-table {
  position: relative;

  .grid-table-title {
    margin: 16px 16px 4px 16px;
    font-size: 1.4rem;
    font-weight: 500;
  }

  .MuiDataGrid-row {
    cursor: pointer;
  }

  .MuiDataGrid-cell.MuiDataGrid-cell--withRenderer {
    padding-left: 0;
    padding-right: 0;
  }

  .bulk-actions-card {
    position: relative;
    max-height: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: inherit;
    height: inherit;
    z-index: 2;
    border-radius: 0;
    background: rgba(128, 128, 128, 0.118);
    transition: max-height 1s ease-in;

    &.expanded {
      transition: max-height 1s ease-in;
      height: 70px;
      max-height: 70px;
    }
  }
}

.entity-table_filter-panel {
  display: flex;
  align-items: center;

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -23px;
  }

  .input {
    margin: 0 5px;
  }
}
