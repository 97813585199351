.auth-container {
  height: 100vh;

  .sign-in-grid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .copyright {
      margin-bottom: 45px;
    }
  }

  .welcome-grid {
    background: url('https://lotic.ai/_next/image?url=https%3A%2F%2Fmedia.graphassets.com%2F1p7Q1oU0Rx2kutgziMhr&w=1920&q=75');
    background-repeat: no-repeat;
    background-position: center;
    background-color: light;
  }
}
