.constructor-items {
  button {
    text-transform: capitalize;
  }
}

.item-columns {
  display: flex;
}

.category {
  margin-top: 20px;
  font-weight: bold;
}

.position-column {
  display: flex;
  flex-direction: column;
  padding: 19px 0;
  justify-content: space-between;
}

.content-column {
  flex-grow: 1;
}

.position-column-item {
  margin-right: 20px;
}
