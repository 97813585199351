@mixin basicFontSize {
  font-size: 0.88rem;
}

.app-bar_email {
  @include basicFontSize;
}

.app-bar_menu-item {
  .MuiTypography-root {
    @include basicFontSize;
  }
}
